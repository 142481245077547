import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { screen, highlight, innerWidth } from "../components/common/variables";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 80px;
  @media ${screen.small} {
    padding-top: 135px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 90px;
  }
  @media ${screen.medium} {
    padding-top: 270px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 120px;
  }

  @media ${screen.xlarge} {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 180px;
    padding-top: 328px;
  }

  .bio {
    max-width: ${innerWidth};
    margin: 0 auto;
    @media ${screen.small} {
      margin: 0 auto;
    }
    @media ${screen.medium} {
      margin: 0 auto;
    }
    @media ${screen.large} {
      margin: 0 auto;
    }
    &__heading {
      font-size: 2.1rem;
      @media ${screen.small} {
        font-size: 2.7rem;
      }
    }

    &__content {
      margin-top: 40px;
      @media ${screen.small} {
        margin-top: 45px;
      }
      .bio-row {
        display: flex;
        flex-direction: column;
        @media ${screen.small} {
          flex-direction: row;
        }

        &__img {
          width: 100%;
          max-width: 480px;
          @media ${screen.small} {
            max-width: 200px;
          }
          @media ${screen.medium} {
            max-width: 250px;
          }
          @media ${screen.large} {
            max-width: 290px;
          }
        }

        &__texts {
          max-width: 950px;
          @media ${screen.small} {
            margin-left: 35px;
          }
          @media ${screen.medium} {
            margin-left: 35px;
          }
          @media ${screen.large} {
            margin-left: 50px;
          }

          .description {
            padding: 0;

            p {
              font-size: 1rem;
              font-weight: 300;
              margin: 0 0 30px 0;
              @media ${screen.small} {
                font-size: 1.1rem;
              }
              &:first-child {
                margin-top: 0;
              }
            }

            strong {
            }
            a {
              color: ${highlight};
              text-decoration: underline;
            }

            h1 {
              font-size: 2.2rem;
              margin: 0 0 8px 0;
              @media ${screen.small} {
                font-size: 2.2rem;
                margin: 0 0 10px 0;
              }
            }
            h2 {
              font-size: 1.8rem;
              margin: 16px 0 8px 0;
              @media ${screen.small} {
                font-size: 2rem;
                margin: 20px 0 10px 0;
              }
            }
            h3 {
              font-size: 1.6rem;
              margin: 16px 0 8px 0;
              @media ${screen.small} {
                font-size: 1.8rem;
                margin: 20px 0 10px 0;
              }
            }
            h4 {
              font-size: 1.4rem;
              margin: 16px 0 8px 0;
              @media ${screen.small} {
                font-size: 1.6rem;
                margin: 20px 0 10px 0;
              }
            }
            h5 {
              font-size: 1.3rem;
              margin: 16px 0 8px 0;
              @media ${screen.small} {
                font-size: 1.4rem;
                margin: 20px 0 10px 0;
              }
            }
            h6 {
              font-size: 1.15rem;
              margin: 16px 0 8px 0;
              @media ${screen.small} {
                font-size: 1.2rem;
                margin: 20px 0 10px 0;
              }
            }
          }
        }
      }
    }
  }
`;

const BioPage = ({ data }) => {
  const {
    page_title,
    description,
    image,
    title_tag,
    meta_description,
  } = data.content.data;
  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="bio">
          <h1 className="bio__heading">{page_title.text}</h1>
          <div className="bio__content">
            <div className="bio-row">
              <div className="bio-row__img">
                {image && (
                  <Img fluid={image.fluid} alt={image.alt || "Nick Portrait"} />
                )}
              </div>
              <div className="bio-row__texts">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description.html }}
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default BioPage;

export const dataQuery = graphql`
  {
    content: prismicBioPage {
      data {
        page_title {
          text
        }
        description {
          html
        }
        image {
          alt
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
